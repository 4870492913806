import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Img from "gatsby-image"
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline"
import { shape, func, string } from "prop-types"
import VideoDialog from "./VideoDialog"
import { useMediaQuery, useTheme } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0,0,0,0)",
    position: "relative",
    cursor: "pointer",
    color: "white",
    overflow: "hidden",
    height: "-webkit-fill-available",
    width: "100%",
    display: "inline-grid",
    "&:hover": {
      color: "#333640",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  playIcon: {
    width: "50%",
    height: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    color: "white",
  },
  playIconMobile: {
    color: "#929B91",
  },
}))

const VideoPopupContainer = ({
  children,
  imgFluid,
  imgFluidMobile,
  defaultImageLink,
  defaultImageClasses,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <VideoDialog
      triggerElement={(props) => (
        <Paper className={classes.root} elevation={0} square {...props}>
          {isMobile ? (
            imgFluidMobile ? (
              <Img
                fluid={imgFluidMobile}
                className={classes.image}
                draggable={false}
              />
            ) : (
              <img
                src={defaultImageLink}
                className={`${classes.image} ${defaultImageClasses}`}
              />
            )
          ) : imgFluid ? (
            <Img fluid={imgFluid} className={classes.image} draggable={false} />
          ) : (
            <img
              src={defaultImageLink}
              className={`${classes.image} ${defaultImageClasses}`}
            />
          )}
          <PlayCircleOutlineIcon
            className={`${classes.playIcon} ${
              isMobile ? classes.playIconMobile : ""
            }`}
          />
        </Paper>
      )}
      videoIframe={(iframeProps) => children(iframeProps)}
    ></VideoDialog>
  )
}

VideoPopupContainer.propTypes = {
  children: func.isRequired,
  imgFluid: shape(),
  imgFluidMobile: shape(),
  defaultImageLink: string,
  defaultImageClasses: string,
}

VideoPopupContainer.defaultProps = {
  imgFluid: undefined, //TODO: can be changed to a default image.
  imgFluidMobile: undefined,
  defaultImageClasses: "",
}

export default VideoPopupContainer
