import React from "react"
import { graphql } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useMediaQuery } from "@material-ui/core"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import MainToolbar from "../components/main-toolbar"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"
import LineTitle from "../components/LineTitle"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Youtube from "../components/Video/Youtube"
import { Button } from "@material-ui/core/index"
import { Helmet } from "react-helmet"
// import QAAccordion from "../components/accordion"

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: "#000",
    },
  },
  bannerGrid: {
    flexDirection: "row",
    ["@media (max-width:600px)"]: {
      paddingBottom: "0px",
      height: "42vh",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: 600,
    },
  },
  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  introHeader: {
    textAlign: "left",
    paddingTop: 0,
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  bodyHeader: {
    paddingTop: 60,
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  bodyText: {
    fontSize: "1.1rem",
    textAlign: "left",
    color: "white",
    maxWidth: 350,
    whiteSpace: "pre-line",
    paddingBottom: 20,
  },
  bodyText2: {
    fontSize: "1.1rem",
    textAlign: "left",
    // color: "#202020",
    color: "white",
    maxWidth: 500,
    whiteSpace: "pre-line",
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  ageingList: {
    fontSize: "1.1rem",
    color: "white",
    paddingLeft: 20,
    maxWidth: 500,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textAlign: "left",
  },
  mainContainer: {
    padding: "0 !important",
    paddingBottom: "120px",
    backgroundColor: "#202020",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "60px",
    },
  },
  titleContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "auto",
      paddingBottom: "auto",
    },
  },
  tooLongtitle: {
    color: "white",
    ["@media (min-width:2040px)"]: {
      fontSize: "4rem",
    },
    ["@media (max-width:1440px)"]: {
      fontSize: "3rem",
    },
    ["@media (max-width:600px)"]: {
      fontSize: "1.5rem",
    },
  },
  tooLongSubheader: {
    color: "#828282",
    textTransform: "uppercase",
    align: "left",
    marginTop: "20px",
    width: "750px",
    fontSize: "1.5rem",
    ["@media (max-width:600px)"]: {
      fontSize: "1rem",
      width: "270px",
    },
  },
  videoText: {
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  sloganLeft: {
    textAlign: "left",
    paddingBottom: "1em",
    fontWeight: 300,
    maxWidth: 350,
    color: "white",
  },
  sloganContainer: {
    alignSelf: "center",
    fontWeight: 300,
  },
  backgroundImg: {
    margin: 0,
    padding: 0,
    paddingBottom: theme.spacing(5),
    // backgroundPosition: "right",
    [theme.breakpoints.up("sm")]: {
      minHeight: "600px",
      // minHeight: "80vh",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "right",
      height: "280px",
    },
  },
  imageContainer: {
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
  },
  sectionContainer: {
    paddingTop: 120,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  sectionContainerExplanation: {
    paddingTop: 40,
    paddingBottom: 30,
    background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 0,
    },
  },
  sectionContainerExplanation2: {
    paddingTop: 40,
    paddingBottom: 130,
    background: "white",
    background: "#343640",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 40,
      paddingBottom: 100,
    },
  },
  gubbeImage: {
    [theme.breakpoints.down("sm")]: {
      height: 220,
    },
  },
  statsImage: {
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
  },
  videoContainer: {
    maxHeight: 400,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      height: 230,
      marginBottom: 40,
    },
  },
  bookNowButton: {
    color: "#fff!important",
    fontSize: "14px!important",
    backgroundColor: "#929B91",
    borderRadius: "40px!important",
    padding: "1.0em 2em!important",
    textDecoration: "none!important",
    marginLeft: "-15%",
    "&:hover": {
      textDecoration: "none!important",
      opacity: "0.5!important",
      backgroundColor: "#929B91!important",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "-19%",
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-24%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "64px!important",
      marginLeft: "-36%",
    },
  },
  clinicBtn: {
    backgroundColor: "#929B91",
  },
}))

const Fillers = ({ intl, data, location }) => {
  const classes = useStyles()
  const theme = useTheme()
  const t = (id) => intl.formatMessage({ id })
  const banner = data.bannerImage.childImageSharp.fluid
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const ageingExplained = [
    t("fillers.ageExplained.1"),
    t("fillers.ageExplained.2"),
    t("fillers.ageExplained.3"),
    t("fillers.ageExplained.4"),
  ]

  return (
    <Layout>
      <Helmet>
        <script
          src="https://schedule.clinicminds.com/assets/widget/widget.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <SEO
        lang={intl.formatMessage({ id: "global.lang" })}
        title={intl.formatMessage({ id: "fillers.SEO" })}
        description={intl.formatMessage({ id: "fillers.SEO" })}
        path={location.pathname}
      />
      <MainToolbar black />
      <Container maxWidth={"xl"} className={classes.mainContainer}>
        <BackgroundImage
          fluid={banner}
          className={classes.backgroundImg}
          backgroundPosition={"right"}
          // style={{ backgroundPosition: "right" }}
          backgroundColor={`#333`}
        >
          <Grid container spacing={0} className={classes.bannerGrid}>
            <Grid item xs={12} sm={6} className={classes.bannerLeft}>
              <Container>
                <LineTitle
                  titleContainer={classes.titleContainer}
                  header={t("fillers.title")}
                  subheader={t("fillers.subtitle")}
                  titleStyle={classes.tooLongtitle}
                  lightDecoLine
                  subHeaderStyle={classes.tooLongSubheader}
                />
                {!smallScreen && (
                  <a
                    href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a&service=b210ed63-6406-11e9-881b-0a599d16f52a"
                    className="clinicminds-scheduler-button"
                    style={{
                      backgroundColor: "#929B91",
                      textDecoration: "none",
                      fontWeight: "normal",
                      fontSize: "16px",
                      padding: "14px 20px",
                      marginTop: "80px",
                      // marginRight: "120px",
                      marginLeft: theme.spacing(6),
                    }}
                  >
                    BOOK APPOINTMENT
                  </a>
                )}
              </Container>
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </Grid>
        </BackgroundImage>
        <Grid container spacing={12} className={classes.sectionContainer}>
          {/* <Grid item xs={12} sm={6} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={classes.introHeader}
            >
              {t("fillers.introTitle")}
            </Typography>
            <p className={classes.bodyText}>{t("fillers.introText")}</p>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.front.childImageSharp.fluid}
              alt="old and young face"
            ></Img> */}
          <Grid item xs={12} sm={6} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={classes.introHeader}
            >
              {t("fillers.intro2Title")}
            </Typography>
            <p className={classes.bodyText}>{t("fillers.intro2Text")}</p>
            {/* <Button
              className={classes.bookNowButton}
              data-leadbox-popup="vsjnFD76SH9pFuxzdjLoom"
              data-leadbox-domain="kooldigital.lpages.co"
            >
              Book consultation
            </Button> */}
            <a
              href="https://schedule.clinicminds.com/?clinic=a8f5f0f3-1e41-11e9-881b-0a599d16f52a&service=b210ed63-6406-11e9-881b-0a599d16f52a"
              className="clinicminds-scheduler-button"
              style={{
                backgroundColor: "#929B91",
                textDecoration: "none",
                fontWeight: "normal",
                fontSize: "16px",
                padding: "16px 24px",
                marginTop: "80px",
                marginRight: "120px",
              }}
            >
              BOOK APPOINTMENT
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.front2.childImageSharp.fluid}
              alt="old and young face"
            ></Img>
          </Grid>
        </Grid>

        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.videoContainer}
          >
            <Youtube
              videoUrl="https://www.youtube.com/watch?v=xn-4uLWAwn0"
              imgFluid={data.explained.childImageSharp.fluid}
            ></Youtube>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            align="center"
            className={classes.sloganContainer}
          >
            <Typography
              component="h2"
              variant="h4"
              className={classes.videoText}
            >
              {t("fillers.videoText")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={12} className={classes.sectionContainer}>
          <Grid item xs={12} sm={6} align="center">
            {/* <QAAccordion></QAAccordion> */}
          </Grid>
          <Grid item xs={12} sm={6} align="center"></Grid>
        </Grid>

        <Grid
          container
          spacing={12}
          direction={smallScreen ? "column-reverse" : "row"}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={7} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={`${classes.introHeader} ${classes.bodyHeader}`}
            >
              {t("fillers.explainedTitle")}
            </Typography>
            <p className={classes.bodyText2}>{t("fillers.introExplain")}</p>
            <ul className={classes.ageingList}>
              {ageingExplained.map((parts) => (
                <li
                  style={{
                    fontStyle: "italic",
                    padding: 0,
                    marginLeft: 0,
                  }}
                >
                  {parts}
                </li>
              ))}
            </ul>
            <p className={classes.bodyText2}>{t("fillers.ageExplain")}</p>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.man.childImageSharp.fluid}
              alt="Man looking in mirror"
              className={classes.gubbeImage}
            ></Img>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={12}
          className={classes.sectionContainerExplanation2}
        >
          <Grid
            item
            xs={12}
            sm={7}
            align="center"
            className={classes.sloganContainer}
          >
            <Typography
              component="h2"
              variant="h4"
              className={classes.sloganLeft}
            >
              {t("fillers.likeAHouse")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            align="center"
            className={classes.imageContainer}
          >
            <Img
              fluid={data.age.childImageSharp.fluid}
              alt="infographic an age aesthetics"
              className={classes.statsImage}
            ></Img>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={12}
          direction={smallScreen ? "column-reverse" : "row"}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={7} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={`${classes.introHeader} ${classes.bodyHeader}`}
            >
              {t("fillers.huidBehandelingenTitle")}
            </Typography>
            <p className={classes.bodyText2}>
              {t("fillers.huidBehandelingen")}
            </p>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={12}
          direction={smallScreen ? "column-reverse" : "row"}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={7} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={`${classes.introHeader} ${classes.bodyHeader}`}
            >
              {t("fillers.btxTitle")}
            </Typography>
            <p className={classes.bodyText2}>{t("fillers.btx")}</p>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={12}
          direction={smallScreen ? "column-reverse" : "row"}
          className={classes.sectionContainerExplanation}
        >
          <Grid item xs={12} sm={7} align="center">
            <Typography
              component="h2"
              variant="h3"
              className={`${classes.introHeader} ${classes.bodyHeader}`}
            >
              {t("fillers.fillersTitle")}
            </Typography>
            <p className={classes.bodyText2}>{t("fillers.fillers")}</p>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={12}
          direction={smallScreen ? "column-reverse" : "row"}
          className={classes.sectionContainerExplanation}
        >
          <Grid
            item
            xs={12}
            sm={7}
            align="center"
            style={{ paddingBottom: "60px" }}
          >
            <Typography
              component="h2"
              variant="h3"
              className={`${classes.introHeader} ${classes.bodyHeader}`}
            >
              {t("fillers.lipoTitle")}
            </Typography>
            <p className={classes.bodyText2}>{t("fillers.lipo")}</p>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default injectIntl(Fillers)

export const query = graphql`
  query {
    bannerImage: file(relativePath: { eq: "italian.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front: file(relativePath: { eq: "mom-and-daughter.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 96) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    front2: file(relativePath: { eq: "susan-schmidt-callum-faris.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 96) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    man: file(relativePath: { eq: "man-wrinkles.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    age: file(relativePath: { eq: "age-fillers-botox.png" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 98) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    explained: file(relativePath: { eq: "filler-intro-thumb-min.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

//Material UI break points:
// xs (extra-small): 0px or larger
// sm (small): 600px or larger
// md (medium): 960px or larger
// lg (large): 1280px or larger
// xl (extra-large): 1920px or larger
